<template>
    <div class="formulas-prize" :class="rarity">
        <div class="formulas-prize__title">
            Congratulations!
            <br />
            You have received {{ prizePreposition }}
            <span>{{ prizeName }}</span>!!!
        </div>

        <!-- <div
            v-if="winningItem.categoryName"
            class="formulas-prize__category"
        >Forge Block</div>-->

        <div class="formulas-prize__image">
            <img width="400" height="400" :src="image" alt="image" />
            <div class="formulas-prize__image-bg" />
        </div>
        <div class="formulas-prize-again__info">
            <div class="formulas-prize__text" v-if="isForgeBlock">
                {{ prizeName }}{{ count > 1 ? 's' : '' }}
                <span v-if="count > 1">x{{ count }}</span>
            </div>
            <div class="formulas-prize__text" v-else>{{ prizeName }}</div>
        </div>

        <div
            class="formulas-prize__continue"
            @click.prevent.stop="$emit('close')"
        >{{ 'Close Window' }}</div>
    </div>
</template>
<script>import moment from "moment";
import { computed, onMounted, watch } from "vue-demi";
import winSound from "@/assets/audio/lootbox_win.mp3";
import commonWinSound from "@/assets/audio/lootbox_win_common.mp3";
import utilitiesMetadata from "@/config/utilities-metadata";
import { resizeImageCF } from "@/helpers/resize-image-cloudflare";

export default {
    components: {},
    props: {
        type: String,
        item: {
            type: Object,
            default: () => ({})
        },
    },
    setup(props) {
        const count = computed(() => props.item.count)
        const isForgeBlock = computed(() => props.type === 'forge_block')
        const startSound = new Audio(isForgeBlock.value ? commonWinSound : winSound)
        const image = computed(() => isForgeBlock.value ? utilitiesMetadata[3].image : resizeImageCF(props.item.image, 1024))
        onMounted(async () => {
            try {
                await startSound.play()
            } catch (err) {
                console.error(err)
            }
        })


        const prizeName = computed(() => {
            return isForgeBlock.value ? 'Forge Block' : props.item.name
        })

        const prizePreposition = computed(() => {
            const letters = ['a', 'e', 'i', 'o', 'u', 'y']
            if (letters.includes(prizeName.value.charAt(0).toLowerCase())) {
                return 'an'
            }
            return 'a'
        })
        const rarity = computed(() => isForgeBlock.value ? 'common' : props.item.rarity)
        return {
            rarity,
            count,
            image,
            prizeName,
            isForgeBlock,
            prizePreposition
        }
    },
    computed: {

    },
};
</script>
  
<style lang="scss">
.formulas-prize {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    min-width: 300px;
    &.utility & {
        &__image {
            color: var(--rarity-common);
        }
        &__title {
            span {
                color: #8c8c8c;
            }
        }
    }
    &.common & {
        &__image {
            color: var(--rarity-common);
        }
        &__title {
            span {
                color: #8c8c8c;
            }
        }
    }
    &.epic & {
        &__image {
            color: var(--rarity-epic);
        }
        &__title {
            span {
                color: var(--rarity-epic);
            }
        }
    }
    &.legendary & {
        &__image {
            color: var(--rarity-legendary);
        }

        &__title {
            span {
                color: var(--rarity-legendary);
            }
        }
    }

    &__category {
        margin-bottom: -16px;
        position: relative;
        z-index: 10;
        background: #232323;
        padding: 6px 28px;
        text-transform: uppercase;
        display: inline-block;
        box-sizing: border-box;
        border-radius: 24px;
        font-size: 16px;
        white-space: nowrap;
        font-weight: bold;
    }
    &__title {
        text-align: center;
        margin-bottom: 16px;
        font-size: 34px;
        font-weight: bold;
        margin-bottom: 15px;
        padding: 0 10px;
        span {
        }
    }
    &__continue {
        font-weight: bold;
        margin-top: 10px;
        text-align: center;
        text-transform: uppercase;
        font-size: 16px;
        padding: 8px 16px;
        width: 100%;
        box-sizing: border-box;
        background-color: var(--primary);
        color: #fff;
        border-radius: 5px;
        cursor: pointer;
        max-width: 300px;
        transition: 0.2s;
        &:hover {
            filter: brightness(0.8);
        }
    }

    &__text {
        font-weight: bold;
        font-size: 24px;
        text-align: center;
        margin-top: 0px;
        color: var(--primary);
    }
    &__image {
        img {
            border-radius: 10px;
            max-width: 100%;
            border: 4px solid currentColor;
            box-sizing: border-box;
            height: auto;
            width: 100%;
        }
        max-width: 500px;
        width: 80%;
        height: auto;
        position: relative;
        &-new {
            position: absolute;
            left: 0;
            top: 0;
            color: var(--yellow);
            left: -20px;
            top: -20px;
        }
        &-bg {
            height: 100vh;
            width: 100vw;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
            overflow: hidden;
            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: block;
                background: url(~@/assets/img/prize-bg.png) center center
                    no-repeat;
                width: 950px;
                height: 934px;
                background-size: contain;
                animation: prizeSpin 9s linear infinite;
            }
        }
    }
    @media screen and (max-width: 1450px) {
        &__image {
            max-width: 400px;

            &-bg {
                &::before {
                    width: 750px;
                    height: 734px;
                }
            }
        }
    }

    @media screen and (max-width: 576px) {
        &__title {
            font-size: 20px;
        }
        &-again {
            padding: 0;
        }
        &-again {
            &__button {
                padding: 10px 15px;

                font-size: 16px;
            }
        }
        &__new {
            font-size: 14px;
        }
    }
}
@keyframes prizeSpin {
    0% {
        transform: translate(-50%, -50%) rotate(0);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
</style>